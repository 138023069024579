export default {
    'lang': 'zh-cn',
    'notFoundContent': '暂无数据',
    'zh-cn': {
        'notFoundContent': '暂无数据'
    },
    'en-us': {
        'notFoundContent': 'Not Found'
    },
    'zh-tw': {
        'notFoundContent': '暫無數據'
    },
    'vi-vn': {
        'notFoundContent': 'Không có dữ liệu'
    },
    'id-id': {
        'notFoundContent': 'Tidak ada data'
    },
    'ar-sa': {
        'notFoundContent': 'لا يوجد بيانات'
    }
}
