module.exports = {
    lang: 'zh-cn',
    msgMax: '值不能大于最大值',
    msgMin: '值不能小于最小值',
    hundred: '百',
    thousand: '千',
    tenThousand: '万',
    hundredThousand: '十万',
    million: '百万',
    tenMillion: '千万',
    hundredMillion: '亿',
    billion: '十亿',
    tenBillion: '百亿',
    hundredBillion: '千亿',
    trillion: '万亿',
    tenTrillion: '十万亿',
    hundredTrillion: '百万亿',
    quadrillion: '千万亿',
    'zh-cn': {
        msgMax: '值不能大于最大值',
        msgMin: '值不能小于最小值',
        hundred: '百',
        thousand: '千',
        tenThousand: '万',
        hundredThousand: '十万',
        million: '百万',
        tenMillion: '千万',
        hundredMillion: '亿',
        billion: '十亿',
        tenBillion: '百亿',
        hundredBillion: '千亿',
        trillion: '万亿',
        tenTrillion: '十万亿',
        hundredTrillion: '百万亿',
        quadrillion: '千万亿',
    },
    'en-us': {
        msgMax: 'Cannot be greater than the Max value',
        msgMin: 'Cannot be less than the Min value',
        hundred: 'Hundred',
        thousand: 'Thousand',
        tenThousand: 'Ten-thousand',
        hundredThousand: 'Hundred-thousand',
        million: 'Million',
        tenMillion: 'Ten-million',
        hundredMillion: 'Hundred-million',
        billion: 'Billion',
        tenBillion: 'Ten-billion',
        hundredBillion: 'Hundred-billion',
        trillion: 'Trillion',
        tenTrillion: 'Ten trillion',
        hundredTrillion: 'Hundred trillion',
        quadrillion: 'Quadrillion',
    },
    'zh-tw': {
        msgMax: '值不能大於最大值',
        msgMin: '值不能小於最小值',
        hundred: '百',
        thousand: '千',
        tenThousand: '萬',
        hundredThousand: '十萬',
        million: '百萬',
        tenMillion: '千萬',
        hundredMillion: '億',
        billion: '十億',
        tenBillion: '百億',
        hundredBillion: '千億',
        trillion: '萬億',
        tenTrillion: '十萬億',
        hundredTrillion: '百萬億',
        quadrillion: '千萬億',
    },
    'vi-vn': {
        msgMax: 'Giá trị không thể lớn hơn mức tối đa',
        msgMin: 'Giá trị không thể thấp hơn giá trị tối thiểu',
        hundred: 'trăm',
        thousand: 'nghìn',
        tenThousand: 'Mười nghìn',
        hundredThousand: 'Một trăm ngàn',
        million: 'triệu',
        tenMillion: 'Mười triệu',
        hundredMillion: '100 triệu',
        billion: 'tỷ',
        tenBillion: 'Mười tỉ',
        hundredBillion: 'Hàng trăm tỷ',
        trillion: 'hàng nghìn tỷ',
        tenTrillion: 'Mười nghìn tỷ',
        hundredTrillion: 'Hàng trăm tỷ',
        quadrillion: 'Hàng triệu triệu',
    },
    'id-id': {
        msgMax: 'Nilai tidak boleh lebih besar dari maksimum',
        msgMin: 'Nilai tidak boleh kurang dari minimum',
        hundred: 'Ratus',
        thousand: 'ribu',
        tenThousand: 'Sepuluh ribu',
        hundredThousand: 'Seratus ribu',
        million: 'juta',
        tenMillion: 'Sepuluh juta',
        hundredMillion: '100 juta',
        billion: 'miliar',
        tenBillion: 'Sepuluh miliar',
        hundredBillion: 'Ratusan miliar',
        trillion: 'triliunan',
        tenTrillion: 'Sepuluh triliun',
        hundredTrillion: 'Ratusan miliar',
        quadrillion: 'Kuadriliun',
    },
    'ar-sa': {
        msgMax: "لا يمكن أن تكون القيمة أكبر من القيمة القصوى",
        msgMin: "لا يمكن أن تكون القيمة أصغر من القيمة الصغرى",
        hundred: "مائة",
        thousand: "ألف",
        tenThousand: "عشرة آلاف",
        hundredThousand: "مائة ألف",
        million: "مليون",
        tenMillion: "عشرة ملايين",
        hundredMillion: "مائة مليون",
        billion: "مليار",
        tenBillion: "عشرة مليارات",
        hundredBillion: "مائة مليار",
        trillion: "تريليون",
        tenTrillion: "عشرة تريليونات",
        hundredTrillion: "مائة تريليون",
        quadrillion: "كوادريليون"
    }
};
