export default {
    'lang': 'zh-cn',
    'zh-cn': {
        'ok': '确定',
        'gotit': '知道了',
        'cancel': '取消',
        'title': '标题',
    },
    'en-us': {
        'title': 'title',
        'ok': 'OK',
        'gotit': 'OK',
        'cancel': 'Cancel',
    },
    'zh-tw': {
        'title': '標題',
        'gotit': '知道了',
        'cancel': '取消',
        'ok': '確定'
    },
    'vi-vn': {
        'ok': 'Chắc chắn',
        'gotit': 'Nhận ra',
        'cancel': 'Hủy bỏ',
        'title': 'Tiêu đề',
    },
    'id-id': {
        'ok': 'Baik',
        'gotit': 'Mengerti',
        'cancel': 'Batal',
        'title': 'Judul',
    },
    'ar-sa': {
        'ok': 'العنوان',
        'gotit': 'فهمت',
        'cancel': 'إلغاء',
        'title': 'موافق',
    }
}
