module.exports = {
    'lang': 'zh-cn',
    'fold': '收起',
    'unfold': '展开',
    'unfix': '取消固定',
    'fixed': '固定侧边栏',
    'zh-cn': {
        'fold': '收起',
        'unfold': '展开',
        'unfix': '取消固定',
        'fixed': '固定侧边栏',
    },
    'en-us': {
        'fold': 'Collapse',
        'unfold': 'Expand',
        'unfix': 'Unfix',
        'fixed': 'Fixed',
    },
    'zh-tw': {
        'fold': '收起',
        'unfold': '展開',
        'unfix': '取消固定',
        'fixed': '固定側邊欄',
    },
    'vi-vn': {
        'fold': 'Thu gọn',
        'unfold': 'Mở rộng',
        'unfix': 'giải nén',
        'fixed': 'sửa chữa',
    },
    'id-id': {
        'fold': 'Letakkan itu pergi',
        'unfold': 'peluncuran',
        'unfix': 'Nyahsemat',
        'fixed': 'tetap',
    }
}
