export default {
    lang: 'zh_cn',
    statuscode: '状态码',
    link: '链路ID',
    errorDetails: '错误详情',
    copy: '复制',
    copySuccess: '复制成功',
    report: '上报',
    reportSuccess: '上报成功',
    reportFail: '上报失败',
    'zh-cn': {
        statuscode: '状态码',
        link: '链路ID',
        errorDetails: '错误详情',
        copy: '复制',
        copySuccess: '复制成功',
        report: '上报',
        reportSuccess: '上报成功',
        reportFail: '上报失败',
    },
    'en-us': {
        statuscode: 'Status Code',
        link: 'Link ID',
        errorDetails: 'Error Details',
        copy: 'Copy',
        copySuccess: 'Copied Successfully',
        report: 'Report',
        reportSuccess: 'Report Successfully',
        reportFail: 'Failed to report',
    },
    'zh-tw': {
        statuscode: '狀態碼',
        link: '連結ID',
        errorDetails: '錯誤詳情',
        copy: '複製',
        copySuccess: '複製成功',
        report: '上報',
        reportSuccess: '上報成功',
        reportFail: '上報失敗',
    },
    'vi-vn': {
        statuscode: 'Mã trạng thái',
        link: 'Liên kết ID',
        errorDetails: 'Thông tin sai',
        copy: 'Sao chép',
        copySuccess: 'Đã sao chép thành công',
        report: 'Đăng báo',
        reportSuccess: 'Báo cáo thành công',
        reportFail: 'Báo cáo thất bại',
    },
    'id-id': {
        statuscode: 'Kode status',
        link: 'Id Tautan',
        errorDetails: 'Rincian kesalahan',
        copy: 'Salinan',
        copySuccess: 'Berhasil Menyalin',
        report: 'Laporan',
        reportSuccess: 'Berhasil dilaporkan',
        reportFail: 'Laporkan kegagalan',
    },
    'ar-sa': {
        statuscode: 'كود الحالة',
        link: 'معرف الرابط',
        errorDetails: 'تفاصيل الخطأ',
        copy: 'نسخ',
        copySuccess: 'تم النسخ بنجاح',
        report: 'تقرير',
        reportSuccess: 'تم الإبلاغ بنجاح',
        reportFail: 'فشل في الإبلاغ'
    }
}