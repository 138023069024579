export default {
    'lang': 'zh-cn',
    'placeholder': '请选择',
    'notFoundContent': '暂无数据',
    'zh-cn': {
        'placeholder': '请选择',
        'notFoundContent': '暂无数据'
    },
    'en-us': {
        'placeholder': 'Please select',
        'notFoundContent': 'Not Found'
    },
    'zh-tw': {
        'placeholder': '請選擇',
        'notFoundContent': '暫無數據'
    },
    'vi-vn': {
        'placeholder': 'Hãy chọn',
        'notFoundContent': 'Không có dữ liệu'
    },
    'id-id': {
        'placeholder': 'Silakan pilih',
        'notFoundContent': 'Tidak ditemukan'
    },
    'ar-sa': {
        'placeholder': 'اختر الرجاء',
        'notFoundContent': 'لا توجد بيانات',
    }
}
