export default {
    lang: 'zh-cn',
    'zh-cn': {
        ok: '确定',
        now: '此刻',
        clear: '清除'
    },
    en: {
        ok: 'OK',
        now: 'Now',
        clear: 'Clear'
    },
    'en-us': {
        ok: 'OK',
        now: 'Now',
        clear: 'Clear'
    },
    'zh-tw': {
        ok: '確定',
        now: '此刻',
        clear: '清除'
    },
    'vi-vn': {
        ok: 'Chắc chắn rồi',
        now: 'Hiện nay',
        clear: 'Làm sạch'
    },
    'id-id': {
        ok: 'Baik',
        now: 'Sekarang',
        clear: 'Bersihkan'
    },
    'ar-sa': {
        ok: 'تأكيد',
        now: 'الآن',
        clear: 'مسح'
    }
} as any
