module.exports = {
    'lang': 'zh-cn',
    'prev': '上一张',
    'next': '下一张',
    'zoomOut': '缩小',
    'zoomIn': '放大',
    'fullscreen': '全屏',
    'rotateLeft': '向左旋转',
    'rotateRight': '向右旋转',
    'flipHorizontal': '左右镜像',
    'flipVertical': '上下镜像',
    'reset': '还原',
    'play': '播放',
    'preview': '预览',

    'zh-cn': {
        'prev': '上一张',
        'next': '下一张',
        'zoomOut': '缩小',
        'zoomIn': '放大',
        'fullscreen': '全屏',
        'rotateLeft': '向左旋转',
        'rotateRight': '向右旋转',
        'flipHorizontal': '左右镜像',
        'flipVertical': '上下镜像',
        'reset': '还原',
        'play': '播放',
        'preview': '预览',
    },
    'en-us': {
        'prev': 'Previous',
        'next': 'Next',
        'zoomOut': 'Zoom Out',
        'zoomIn': 'Zoom In',
        'fullscreen': 'FullScreen',
        'rotateLeft': 'Rotate Left',
        'rotateRight': 'Rotate Right',
        'flipHorizontal': 'Flip Horizontal',
        'flipVertical': 'Flip Vertical',
        'reset': 'Reset',
        'play': 'Play',
        'preview': 'Preview',
    },
    'zh-tw': {
        'prev': '上一張',
        'next': '下一張',
        'zoomOut': '縮小',
        'zoomIn': '放大',
        'fullscreen': '全屏',
        'rotateLeft': '向左旋轉',
        'rotateRight': '向右旋轉',
        'flipHorizontal': '左右鏡像',
        'flipVertical': '上下鏡像',
        'reset': '還原',
        'play': '播放',
        'preview': '預覽',
    },
    'vi-vn': {
        'prev': 'Trước',
        'next': 'Tiếp',
        'zoomOut': 'Thu nhỏ',
        'zoomIn': 'Phóng to',
        'fullscreen': 'Toàn màn hình',
        'rotateLeft': 'Xoay trái',
        'rotateRight': 'Xoay phải',
        'flipHorizontal': 'Lật ngang',
        'flipVertical': 'Lật dọc',
        'reset': 'Thiết lập lại',
        'play': 'Chơi',
        'preview': 'Xem trước',
    },
    'id-id': {
        'prev': 'Sebelumnya',
        'next': 'Berikutnya',
        'zoomOut': 'Zoom Keluar',
        'zoomIn': 'Zoom Masuk',
        'fullscreen': 'Layar Penuh',
        'rotateLeft': 'Putar Kiri',
        'rotateRight': 'Putar Kanan',
        'flipHorizontal': 'Flip Horizontal',
        'flipVertical': 'Flip Vertical',
        'reset': 'Reset',
        'play': 'Putar',
        'preview': 'Pratinjau',
    },
    'ar-sa': {

        'prev': 'الصورة السابقة',
        'next': 'الصورة التالية',
        'zoomOut': 'تصغير',
        'zoomIn': 'تكبير',
        'fullscreen': 'FULLSCREEN', // 注意：通常“全屏”模式的标识会使用大写的“FULLSCREEN”，但也可以根据需要翻译成“الشاشة الكاملة”
        'rotateLeft': 'تدوير لليمين',
        'rotateRight': 'تدوير لليسار',
        'flipHorizontal': 'انعكاس أفقي',
        'flipVertical': 'انعكاس عمودي',
        'reset': 'إعادة تعيين',
        'play': 'تشغيل',
        'preview': 'معاينة',
    }
}